import shoppingbg from "../../assets/image/shoppingbg.jpg";
import { Popconfirm } from "antd";
import { styled } from "styled-components";
import { color } from "../../theme/Color";

export const CartContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 4rem;
`;
export const ButtonMobile = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
export const CardButtonMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
  border: none;
  .buttonStyle {
    width: 89%;
    height: 87px;
    flex-shrink: 0;
    border-radius: 5px;
    background: ${color._ffc636};
    color: ${color._15374D};
    border: none;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const Icon = styled.div`
  width: 31px;
  height: 31px;
  background: ${color._FFBE32};
  border-radius: 16px;
  .icon {
    margin-left: 8px;
    margin-top: 3px;
  }
`;

export const CartHeading = styled.div`
  color: ${color._0f2a3c};
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 45px;
  }
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${color._6091a8};
  margin: 10px auto 30px;
  &.line {
    background: #dadada;
  }
`;

export const CourseCountSection = styled.div`
  color: ${color._16384e};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: left;
  margin-bottom: 10px;
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;

export const ImageCard = styled.img`
  width: 100%;
  display: block;
`;

export const Background = styled.div`
  border-radius: 2px;
  background: rgba(1, 56, 79, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
`;

export const ImageCardContainer = styled.div`
  margin: 0px auto;
  position: relative;
`;
export const HoveredItem = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: left;
`;

export const ShoppingCartSection = styled.div`
  width: 100%;
  height: auto;
  flex-shrink: 0;
  opacity: 0.96;
  background: url(${shoppingbg});
  padding: 25px 20px;
  gap: 15px;
`;
export const CardSection = styled.div`
  width: 90%;
  flex-shrink: 0;
  border-radius: 7px;
  background: ${color._fff};
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  margin-bottom: 30px;
  @media only screen and (max-width: 800px) {
    height: 100%;
    width: 95%;
  }
`;
export const CardImageSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CardDescriptionSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  @media only screen and (max-width: 800px) {
    .desMobile {
      display: flex;
      gap: 3px;
    }
    .chipMobile {
      display: flex;
      justify-content: space-between;
    }
  }
`;
export const CardDeleteSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -5px;
  cursor: pointer;
  color: ${color._6d7d8b};
  .delete {
    &:hover {
      color: #16384e;
    }
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
export const CardDeleteSectionMobile = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
    position: relative;
    left: 196%;
    bottom: 30px;
  }
`;
export const Pop = styled(Popconfirm)`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
    .ant-popconfirm .ant-popconfirm-message {
      display: contents;
      position: fixed;
    }
    .ant-popconfirm .ant-popconfirm-message .ant-popconfirm-title {
      font-size: 26px;
    }
    .ant-popconfirm .ant-popconfirm-message .ant-popconfirm-description {
      font-size: 26px;
    }
    .ant-popconfirm .ant-popconfirm-buttons button {
      width: 70px;
      height: 48px;
      font-size: 30px;
    }
    .ant-popconfirm
      .ant-popconfirm-message
      > .ant-popconfirm-message-icon
      .anticon {
      font-size: 34px;
    }
  }
`;
export const CartPriceSectionMobile = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
    margin-top: 8px;
    .priceLabel {
      display: flex;
      justify-content: center;
    }
    .total {
      color: ${color._16384e};
      font-size: 30px;
      font-weight: 800;
    }
  }
`;
export const CourseChipName = styled.div`
  width: fit-content;
  padding: 10px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._ebf6fb};
  display: flex;
  justify-content: center;
  color: ${color._6d7d8b};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  @media only screen and (max-width: 800px) {
    height: 65px;
    font-size: 29px;
  }
`;
export const OnlineCoursesMobile = styled.div``;
export const Description = styled.h1`
  color: ${color._16384e};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 30px;
    text-align: justify;
    width: 42%;
  }
`;
export const Gap = styled.div`
  margin-bottom: 8px;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
export const PriceAmount = styled.div`
  color: ${color._16384e};
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;
export const PricingSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  gap: 0.5rem;
  @media only screen and (max-width: 800px) {
    display: none;
    .courseMobile {
      display: flex;
    }
  }
`;
export const LeftSection = styled.div`
  flex: 0.6;
  @media only screen and (max-width: 800px) {
    position: relative;
    left: 3%;
  }
`;
export const RightSection = styled.div`
  flex: 0.4;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
export const MainSection = styled.div`
  display: flex;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
export const CalculationSection = styled.div`
  width: 376px;
  height: 225px;
  flex-shrink: 0;
  background: ${color._fff};
  padding: 15px;
`;
export const CalculationLabel = styled.div`
  display: flex;
  justify-content: center;
  color: ${color._5193aa};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0px;
`;
export const LastContainer = styled.div`
  height: 200px;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
export const CartPriceSection = styled.div`
  display: flex;
  align-items: center;
`;
export const CartPriceLabel = styled.div`
  flex: 0.4;
  color: ${color._16384e};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  @media only screen and (max-width: 800px) {
    text-align: justify;
    font-size: 29px;
  }
`;
export const CartPrice = styled.div`
  display: flex;
  flex: 0.6;
  color: ${color._16384e};
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  justify-content: flex-start;
  align-content: flex-start;
`;
export const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 25px 0px;
  border: none;
  .buttonStyle {
    width: 95%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background: ${color._ffc636};
    color: ${color._15374D};
    border: none;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
`;
export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const OnlineCourses = styled.div`
  display: flex;
  width: 30%;
  color: ${color._5193aa};
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    width: 37%;
    font-size: 38px;
    margin-top: 20px;
  }
`;
export const PriceMobileRes = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
    width: 100%;
    .courseMobile {
      display: flex;
      justify-content: space-between;
    }
    .licenseMobile {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }
  }
`;
export const SaveLaterCourses = styled.div`
  width: 20%;
  display: flex;
  margin-top: 15px;
  justify-content: end;
  align-items: center;
  color: ${color._68828e};
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 5px;
  @media only screen and (max-width: 800px) {
    width: 50%;
    font-size: 30px;
  }
  .clockIcon {
    width: 14px;
    height: 14px;
    @media only screen and (max-width: 800px) {
      width: 30px;
      height: 30px;
    }
  }
`;
export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 20px;
`;

export const ButttonContainer = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 20px;
  width: 50%;
  @media only screen and (max-width: 800px) {
    width: 25%;
  }
  .decrementIcon {
    width: 18.41px;
    height: 18.41px;
    color: #000;
    @media only screen and (max-width: 800px) {
      width: 40.41px;
      height: 40.41px;
    }
  }
  .add_ {
    display: flex;
    background: none;
    border-radius: 5px;
    color: ${color._fff};
    border: 0.5px solid ${color._6091a8};
    align-items: center;
    width: 40px;
    height: 40px;
    justify-content: center;
    &:hover {
      width: 40px;
      height: 40px;
      background: rgba(237, 237, 238, 1);
      padding: 10px;
      gap: 10px;
      border-radius: 5px;
      border: 0.5px;
    }
    @media only screen and (max-width: 800px) {
      width: 60px;
      height: 60px;
    }
  }
  .decrement {
    background: none;
    display: flex;
    border-radius: 5px;
    color: ${color._fff};
    border: 0.5px solid ${color._6091a8};
    align-items: center;
    width: 40px;
    height: 40px;
    justify-content: center;
    &:hover {
      width: 40px;
      height: 40px;
      background: rgba(237, 237, 238, 1);
      padding: 10px;
      gap: 10px;
      border-radius: 5px;
      border: 0.5px;
    }
    @media only screen and (max-width: 800px) {
      width: 60px;
      height: 60px;
    }
  }
  .count {
    display: flex;
    border-radius: 5px;
    border: 0.5px solid ${color._6091a8};
    align-items: center;
    background: ${color._0f2a3c};
    color: ${color._fff};
    width: 49px;
    text-align: center;
    height: 40px;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    @media only screen and (max-width: 800px) {
      width: 60px;
      height: 60px;
      font-size: 30px;
    }
  }
`;

export const Para = styled.p`
  color: ${color._0f2a3c};
  font-size: 15px;
  font-weight: 300;
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;

export const Input = styled.input`
  outline: none;
`;
