import { styled } from "styled-components";
import { color } from "../../../theme/Color";

export const Button = styled.button`
  background: ${color._15374D};
  color: ${color._ffffff};
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  border: 0;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &:hover {
    background-color: rgb(81, 147, 170);
  }
`;

export const Icon = styled.div`
  color: ${color._ffffff};
  .rightIcon {
    width: 17px;
    height: 14.537px;
  }
  &:hover {
    color: #ffffff;
  }
`;

export const IconPrev = styled.div`
  color: ${color._ffffff};
  .leftIcon {
    width: 17px;
    height: 14.537px;
  }
`;
export const NextButton = styled.span`
  position: relative;
  left: ${(props) =>
    props.avtavNextButton === "avtavNextButton"
      ? "20%"
      : props.nextOrder === "nextOrder"
      ? "-5%"
      : "-12%"};
`;

export const PrevNextDone = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;
export const ButtonPrev = styled.button`
  background: ${color._DADADA};
  color: ${color._ffffff};
  border-color: ${color._ffffff};
  width: 55px;
  height: 55px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 0;
  &:hover {
    background-color: rgb(81, 147, 170);
  }
`;
export const PreviousButton = styled.span`
  position: relative;
  top: 8%;
  margin: 0 8px;
  right: ${({ avtavPrevButton }) => (avtavPrevButton ? "20%" : "-11%")};
`;
export const Stepper = styled.div`
  width: ${(props) =>
    props.status === "isCorporate"
      ? "50%"
      : props.status === "isInvited"
      ? "38%"
      : props.status === "browse"
      ? "30%"
      : props.status === "orderDetails"
      ? "25%"
      : "45%"};

  margin: ${(props) =>
    props.status === "isCorporate"
      ? "0 auto"
      : props.status === "isInvited"
      ? "0 auto"
      : props.status === "browse"
      ? "50px auto"
      : props.status === "orderDetails"
      ? "50px auto"
      : "0 auto"};

  @media only screen and (max-width: 800px) {
    width: ${(props) =>
      props.status === "browse"
        ? "50%"
        : props.status === "orderDetails"
        ? "42%"
        : "100%"};
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    @media only screen and (max-width: 800px) {
      color: ${color._5193aa};
    }
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item {
    @media only screen and (max-width: 800px) {
      display: flex;
      flex: none;
      width: ${(props) => (props.status === "orderDetails" ? "auto" : "41%")};
    }
  }

  .ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
      props.status === "isInvited"
        ? "center"
        : props.status === "orderDetails"
        ? "space-between"
        : null};
  }

  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${color._DADADA};
  }

  .ant-steps-item-title {
    min-width: 126px;
    color: ${color._5193aa};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ant-steps .ant-steps-item-container .ant-steps-item-icon {
    background-color: ${color._5193aa};
    border-color: ${color._5193aa};
    width: 25px;
    height: 25px;
    margin-top: 0;
    margin-bottom: 0;
    margin-inline: 0 8px;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border-radius: 24px;
  }

  .ant-steps .ant-steps-item-tail {
    width: ${(props) => (props.status === "orderDetails" ? "93%" : "100%")};
  }
  .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${color._DADADA};
  }
  .ant-steps .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
    font-size: 14px;
    font-style: normal;
    color: ${color._ffffff};
    font-weight: 400;
    line-height: normal;
  }
  .ant-steps .ant-steps-item-container .ant-steps-item-tail {
    margin-inline-start: 45px;
    padding: 1px 24px;
  }
  .ant-steps .ant-steps-item-container .ant-steps-item-tail::after {
    width: 129%;
    height: 4px;
    @media (min-width: 1400px) {
      width: 130%;
    }
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    @media only screen and (max-width: 800px) {
      background-color: transparent;
    }
  }

  .ant-steps.ant-steps-label-vertical .ant-steps-item-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ant-steps
    .ant-steps-item-container
    .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon {
    color: ${color._ffffff};
  }
  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${color._5193aa};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
