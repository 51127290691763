import { styled } from "styled-components";
import { color } from "../../theme/Color";

export const Container = styled.div`
  color: ${color._0f2a3c};
  align-items: center;
  display: flex;
  gap: 0.2rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 30px;
  margin-bottom: 8px;
`;
export const ItemData = styled.a`
  display: flex;
  gap: 0.2rem;
  color: ${color._0F2A3C};
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
`;

export const NavUrlTex = styled.div`
  color: ${color._0f2a3c};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const LogoItem = styled.div`
  display: flex;
  align-items: center;
  color: ${color._0f2a3c};
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 9px;
  margin-bottom: 12px;
  @media only screen and (max-width: 800px) {
    .logoDark {
      width: 38px;
    }
  }
`;
export const StatsItem = styled.div`
  display: flex;
  gap: 2rem;
  width: 93%;
  color: ${color._16384e};
  font-family: Helvetica Neue;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 3px;
  border: 0.3px solid rgba(21, 55, 77, 0.28);
  background: rgba(241, 251, 255, 0.89);
  padding: 15px 15px;
  margin-bottom: 11px;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const StatsData = styled.div`
  display: flex;
  gap: 0.4rem;
  .activeText {
    color: ${color._0f2a3c};
    font-size: 13px;
    font-weight: 300;
  }
  @media only screen and (max-width: 800px) {
    .langIcon {
      width: 30px;
      font-size: 26px;
      display: flex;
    }
    .duration {
      font-size: 20px;
    }
    .valText {
      font-size: 20px;
    }
    .activeText {
      font-size: 20px;
    }
  }
`;
export const Starcontainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  .text {
    color: ${color._15374d};
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }
`;
export const StarIcon = styled.div`
  display: flex;
  color: ${color._FFC636};
`;
export const SpanDataitem = styled.div`
  margin-bottom: 52px;
  display: flex;
  gap: 2rem;
  color: ${color._0f2a3c};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 91%;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.89);
  padding: 17px 27px;
  margin-bottom: 17px;
`;
export const Dataitem = styled.span`
  color: ${color._0f2a3c};
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 91%;
  .years {
    color: ${color._0f2a3c};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
  }
`;
export const MainContainer = styled.div`
  display: flex;
  position: relative;
  @media (max-width: 1230px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
export const LeftColMobile = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
    .mblLeft {
      padding: 0px 69px;
    }
  }
`;
export const LeftCol = styled.div`
  flex: 0.6;
  @media only screen and (max-width: 800px) {
    .mblLeft {
      padding: 0px 69px;
    }
  }
`;
export const RightCol = styled.div`
  flex: 0.4;
  position: relative;
  justify-content: end;
  display: flex;
  @media only screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
  }
`;

export const SecurityCard = styled.div`
  top: 10%;
  position: absolute;
  width: 85%;
  display: flex;
  justify-content: end;
  & .card_Security {
    border-radius: 5px;
    display: flex;
    justify-content: end;
    flex-direction: column;
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    position: relative;
    margin-top: 57px;
    width: 67%;
  }
`;

export const Para = styled.div`
  margin-bottom: 52px;
  color: ${color._0F2A3C};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 611px;
  border-left: 6px solid ${color._FFBE32};
  padding-left: 1rem;
  @media only screen and (max-width: 800px) {
    font-size: 26px;
    width: 73%;
  }
`;
export const Line = styled.div`
  text-align: center;
  display: flex;
  align-items: start;
  position: absolute;
  left: 17px;
  color: ${color._767676};
  width: 1%;
  background: ${color._16384E};
  height: 56%;
`;
export const LineContainer = styled.div`
  flex: 0.1;
  display: flex;
  position: relative;
  justify-content: end;
  align-items: end;
  .line {
    position: absolute;
    top: 40px;
    width: 142px;
    transform: rotate(-90deg);
    display: flex;
    justify-content: end;
    align-items: end;
    left: -55px;
  }
  @media only screen and (max-width: 800px) {
    .line {
      font-size: 15px;
    }
  }
`;
export const ModulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 75px;
`;
export const ModuleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Module = styled.div`
  flex-direction: row;
  display: flex;
  @media only screen and (max-width: 800px) {
    padding: 0px 69px;
  }
`;
export const ModulesIncluded = styled.div`
  padding-top: 46px;
  padding-bottom: 46px;
  display: flex;
  background: ${color._eff3f9};
`;
export const ModulesSpan = styled.span`
  color: ${color._15374d};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 36px;
  }
`;
export const List = styled.div`
  color: ${color._767676};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 200.9%;
  li::marker {
    color: ${color._15374d};
  }
  @media only screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
export const Text = styled.div`
  width: 100%;
  justify-content: space-between;
  height: 64px;
  top: 0px;
  border-radius: 5px;
  background: ${color._15374d};
  box-shadow: 7px 0px 0px 0px inset #009be3;
  display: flex;
  align-items: center;
`;

export const LinkText = styled.a`
  .arrow {
    font-size: 24px;
  }
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.2);
  color: ${color._fff};
  padding: 3px 11px;
  height: 28px;
  text-decoration: none;
  .tryText {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .avatvText {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .businessText {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const Multiusercontainer = styled.div`
  margin-top: 10px;
  position: relative;

  .relatedCourseWrapper {
    width: 90%;
    margin: 0 auto;
  }
`;

export const IconText = styled.div`
  margin-left: 11px;
  align-items: center;
  color: ${color._fff};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 1rem;
  .icon {
    height: 24px;
    width: 24px;
  }
  .notice {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const AlertIcon = styled.div`
  .alerticon {
    font-size: 24px;
  }
`;
export const TryLink = styled.div`
  border-radius: 2px;
  border: 0.5px solid rgba(21, 55, 77, 0.1);
  background: ${color._f1fbff};
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 15px 24px;
`;
export const RelatedCourses = styled.div`
  display: grid;
  justify-content: center;
  .line {
    height: 4px;
    background: ${color._FFBE32};
  }
`;
export const ListContainer = styled.div`
  background: ${color._EFF3F9};
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 800px) {
    margin-top: 41px;
  }
`;
export const AlertText = styled.span`
  color: ${color._16384e};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  &.try {
    margin-top: 8px;
  }
`;
export const AvtavLink = styled.a`
  width: 167px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 8px;
  border-radius: 2px;
  background: ${color._15374d};
  color: ${color._fff};
  font-size: 16px;
  font-style: normal;
  text-decoration: none;
  font-weight: 700;
  line-height: normal;
  .linktext {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .businessText {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .arrow {
    font-size: 24px;
  }
`;
export const Related = styled.div`
  color: ${color._15374D};
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 51px;
  @media only screen and (max-width: 800px) {
    font-size: 37px;
  }
`;
export const Regulated = styled.div`
  display: flex;
  gap: 1rem;
  color: ${color._08232f};
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 36px 0px;
  @media only screen and (max-width: 800px) {
    .dark {
      width: 37px;
    }
    position: relative;
    left: 6%;
  }
`;
export const CardSection = styled.div`
  width: 100%;
`;
export const CrossIcon = styled.div`
  color: ${color._fff};
  font-size: 24px;
  margin-right: 1rem;
  .cross {
    background: none;
    border: none;
    color: ${color._fff};
  }
`;
export const Regulatedtext = styled.div`
  color: ${color._08232F};
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 35px;
  }
`;
export const CardText = styled.div`
  padding-bottom: 12px;
  color: ${color._767676};
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 1.938rem;
  }
`;
export const NumberBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 7px;
`;
export const CardNumber = styled.div`
  .call {
    color: ${color._6d7d8b};
  }
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${color._16384e};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  @media only screen and (max-width: 800px) {
    font-size: 25px;
  }
`;
export const PhoneNumber = styled.div`
  color: ${color._16384e};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  @media only screen and (max-width: 800px) {
    font-size: 25px;
  }
`;
export const Email = styled.div`
  .mail {
    color: ${color._6d7d8b};
  }
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  gap: 15px;
  align-items: center;
  color: ${color._08232f};
  padding-bottom: 12px;
  @media only screen and (max-width: 800px) {
    font-size: 25px;
  }
`;
export const CardButton = styled.div`
  padding-bottom: 14px;
  .buttonStyle {
    border-radius: 2px;
    background: ${color._ffc636};
    border: none;
    width: 95%;
    padding: 6px 0px;
    color: ${color._0f2a3c};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
    @media only screen and (max-width: 800px) {
      font-size: 31px;
    }
  }
  @media only screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
  }
`;
export const Card = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  @media only screen and (max-width: 800px) {
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    gap: 5%;

    padding: 0px 25px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
