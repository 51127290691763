import * as Styled from "./style";
import OrderLogo from "../../../assets/Svg/OrderLogo.svg";
import React, { useRef } from "react";
import { Checkboxdata, Orderdata, Paragraph } from "./data";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import Invoice from "../../Organisms/Invoice/Invoice";

const OrderDetails = () => {
  const orderDetails = useSelector(
    (state) => state.checkoutReducer.checkout.share_data
  );

  let cartTotal = 0;
  orderDetails?.cart &&
    orderDetails?.cart?.forEach((item) => {
      cartTotal += parseInt(item?.price);
    });

  // Download Functionlity.............//
  const generateAndDownloadPDF = async () => {
    const captureParent = document.querySelector(".order-receipt");
    captureParent.scrollTop = captureParent.scrollHeight;
    captureParent.style.opacity = 1;
    captureParent.style.height = "1px";
    captureParent.style.overflow = "auto";
    const capture = document.querySelector(".order-receipt > div");
    capture.style = {};
    var height = capture.offsetHeight;
    var width = capture.offsetWidth;
    capture.style.width = `${width}px`;
    capture.style.height = `${height}px`;
    html2canvas(capture).then((canvas) => {
      const doc = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("img/jpg");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("Receipt.pdf");
      captureParent.style.opacity = 0;
      captureParent.style.height = "1px";
      captureParent.style.overflow = "auto";
    });
  };
  // PDF functionality..................//
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const vat = orderDetails?.order?.vatTax ? orderDetails?.order?.vatTax : 0;
  const totalAmount = cartTotal + parseInt(vat);
  return (
    <>
      <div>
        <Styled.Order>
          <Styled.OrderText>
            <div className="details">{Paragraph.OrderDetails}</div>
            <div>
              <span className="order">{Paragraph.Order}</span>
              <span className="number">{`#${orderDetails?.order?.id}`}</span>
            </div>
          </Styled.OrderText>
          <Styled.LogoContainer>
            <Styled.Logo>
              <img src={OrderLogo} alt="logo" />
            </Styled.Logo>
            <Styled.Checkbox>
              {Checkboxdata?.map((item, id) => {
                return <div key={id}>{item.name}</div>;
              })}
            </Styled.Checkbox>
          </Styled.LogoContainer>
        </Styled.Order>

        <Styled.OrderDescription>
          <Styled.OrderDataContainer>
            <tbody>
              <tr className="line">
                <td className="product">
                  <Styled.Orderdata>{Orderdata.Product}</Styled.Orderdata>
                </td>
                <td className="quantity">
                  <Styled.Orderdata>{Orderdata.Quantity}</Styled.Orderdata>
                </td>
                <td className="price">
                  <Styled.Orderdata>{Orderdata.Price}</Styled.Orderdata>
                </td>
              </tr>
              {orderDetails?.cart?.map((item) => {
                return (
                  <>
                    <tr className="line">
                      <td>
                        <Styled.OrderdataGeneralSecurity normalFont={true}>
                          {item?.Course?.title}
                        </Styled.OrderdataGeneralSecurity>
                      </td>
                      <td className="quantity">
                        <Styled.Orderdata>{item?.qty}</Styled.Orderdata>
                      </td>
                      <td className="value">
                        <Styled.Orderdata>
                          {Number(item?.price)?.toFixed(2)}
                        </Styled.Orderdata>
                      </td>
                    </tr>
                  </>
                );
              })}

              <tr className="line">
                <td colspan="2">
                  <Styled.Orderdata>{Orderdata.Subtotal}</Styled.Orderdata>
                </td>
                <td className="value">
                  <Styled.Orderdata>
                    {Number(cartTotal)?.toFixed(2)}
                  </Styled.Orderdata>
                </td>
              </tr>

              <tr className="line">
                <td colspan="2">
                  <Styled.Orderdata>{Orderdata.vat}</Styled.Orderdata>
                </td>
                <td className="value">
                  <Styled.Orderdata>{Number(vat)?.toFixed(2)}</Styled.Orderdata>
                </td>
              </tr>

              <tr className="line">
                <td colspan="2">
                  <Styled.Orderdata>{Orderdata.PaymentMethod}</Styled.Orderdata>
                </td>
                <td className="value">
                  <Styled.OrderdataMasterCard normalFont={true}>
                    {orderDetails?.order?.paymentMethod}
                  </Styled.OrderdataMasterCard>
                </td>
              </tr>

              <tr className="line">
                <td colspan="2">
                  <Styled.Orderdata>{Orderdata.Total}</Styled.Orderdata>
                </td>
                <td className="value">
                  <Styled.Orderdata>
                    {Number(totalAmount)?.toFixed(2)}
                  </Styled.Orderdata>
                </td>
              </tr>
            </tbody>
          </Styled.OrderDataContainer>
        </Styled.OrderDescription>
        <Styled.PrintButton>
          <button className="print" onClick={handlePrint}>
            {Paragraph.PrintInvoice}
          </button>
          <button className="download" onClick={generateAndDownloadPDF}>
            {Paragraph.DownloadInvoice}
          </button>
        </Styled.PrintButton>
      </div>
      <div
        className="order-receipt"
        style={{
          position: "absolute",
          zIndex: "-1000",
          overflow: "hidden",
          height: "1px",
          width: "75%",
          opacity: "0",
        }}
      >
        <div ref={componentRef}>
          <Invoice />
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
