import Accordion from "react-bootstrap/Accordion";
import styled from "styled-components";
import { color } from "../../theme/Color";

export const InputCheck = styled.div`
  display: flex;
  justify-content: center;
  gap: 38px;
  @media only screen and (max-width: 800px) {
    gap: 3%;
    padding: 15px;
  }
`;
export const Solo = styled.div`
  width: 846px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid ${color._EFF3F9};
  background: ${color._ffffff};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  .backCheck {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px 20px 2px 2px;
    background: ${color._5193aa};
    height: 53px;
    color: ${color._ffffff};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .single {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;
export const NextLine = styled.div`
  width: 87%;
  height: 1px;
  background: ${color._eaedef};
  margin: 50px auto;

  @media only screen and (max-width: 800px) {
    height: 2px;
  }
`;
export const CheckRadio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px 0px;
`;
export const EmailPara = styled.div`
  margin: 0 auto;
  padding: 30px 0px;
  width: 94%;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  .add-space {
    padding: 8px 0px;
  }
  .empbtn {
    color: ${color._ffffff};
    border: none;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._009be3};
    padding: 11px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #177a9c;
      color: #ffffff;
    }
  }
`;
export const EmpBtn = styled.div`
  margin-left: 12px;
  flex: 1;
  display: flex;
  align-items: end;
  padding-bottom: 1px;
  .mailbtn {
    color: ${color._ffffff};
    border: none;
    text-align: center;
    cursor: no-drop;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 286px;
    height: 37px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._bac2c5};
  }
`;
export const NextLined = styled.div`
  width: 87%;
  height: 1px;
  background: ${color._EFF3F9};
  margin: 50px 0px;
`;
export const Box = styled.div`
  width: 846px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid ${color._EFF3F9};
  background: ${color._ffffff};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  .backCheck {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px 20px 2px 2px;
    background: ${color._5193aa};
    height: 53px;
    color: ${color._ffffff};
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .single {
    color: ${color._ffffff};
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .pipe {
    margin: 0px 8px;
  }
`;
export const Columns = styled.div``;
export const Label = styled.label`
  color: ${color._000000};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
  .star {
    color: ${color._f20000};
  }
  gap: 3px;
  display: flex;
`;
export const EmpData = styled.div`
  width: 313px;
  margin-top: 10px;
  flex: 0.6;
`;

export const AccountantField = styled.div`
  width: 50%;
`;
export const Input = styled.input`
  &.inputEmp {
    outline: none;
    width: 313px;
    height: 38px;
    border-radius: 3px;
    background: ${color._F6F6F6};
    &::placeholder {
      padding: 5px;
    }
  }
  &.inputAccountant {
    outline: none;
    width: 350px;
    height: 38px;
    border-radius: 3px;
    background: ${color._F6F6F6};
    padding: 5px;
  }
  &.inputAddress {
    width: 94%;
    height: 38px;
  }
  width: 86%;
  height: 35px;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  margin-top: 8px;
`;
export const ParaDec = styled.div`
  color: rgba(0, 0, 0, 0.66);
  margin-top: 15px;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
export const CheckboxEmail = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const Contents = styled.div`
  position: relative;
`;
export const DeclareContainer = styled.div`
  position: absolute;
  left: 7%;
  align-items: center;
  gap: 5px;
  display: flex;

  @media only screen and (max-width: 800px) {
    align-items: center;
    justify-content: center;
    padding-top: 6%;
    gap: 5px;
    position: unset;
  }

  .declare {
    color: ${color._0F2A3C};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .aviation {
    color: ${color._0F2A3C};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const NextStep = styled.div`
  .next {
    width: 118px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 26px;
    background: ${color._082429};
    border: none;
    color: ${color._ffffff};
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
`;
export const NextSteps = styled.div`
  width: 846px;
  justify-content: end;
  position: relative;
  left: 19%;
  margin-top: 25px;
  display: flex;
  .next {
    width: 118px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 26px;
    background: ${color._082429};
    border: none;
    color: ${color._ffffff};
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
`;

export const BtnNext = styled.div`
  width: 100%;
`;
export const UserRadioButton = styled.div`
  display: flex;
  gap: 10px;
  .content {
    height: 17px;
    width: 17px;
  }
`;
export const Lined = styled.div`
  width: 100%;
  height: 1px;
  background: ${color._eaedef};
  margin: 25px auto;
`;
export const Para = styled.div`
  margin: 0 auto;
  word-spacing: 3px;
  color: #16384e;
  font-size: 14px;
  font-weight: 400px;
`;

export const ContainerSingle = styled.div`
  width: 846px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid ${color._EFF3F9};
  background: ${color._ffffff};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  .backInfo {
    color: ${color._f1fbff};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Open Sans;
    height: 53px;
    font-size: 16px;
    background: ${color._5193aa};
    border-radius: 20px 20px 2px 2px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .rows {
    padding: 43px 54px;
  }
  .content {
    font-weight: 400;
    color: #16384e;
  }
`;
export const Paragraph = styled.div`
  padding-top: 5px;
`;

export const Mains = styled.div``;

export const Requirement = styled.div`
  margin-top: 30px;
`;
export const FirstContent = styled.div`
  width: 334px;
  .details {
    color: ${color._5193aa};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;
export const Price = styled.div`
  display: flex;
  gap: 16%;
`;
export const CourseName = styled.div`
  margin: 15px 0px 15px 0px;
  .name {
    background: ${color._f1fbff};
    width: 84px;
    height: 17px;
    color: ${color._5193aa};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .gsat {
    position: relative;
    padding-left: 8px;
    right: 7px;
    color: ${color._16384E};
    padding-top: 11px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .qty1 {
    color: ${color._5193aa};
    width: 30px;
    margin-top: 12px;
    background: ${color._f1fbff};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 1px 5px;
    height: 17px;
  }
  .qty2 {
    color: ${color._5193aa};
    width: 111px;
    margin-top: 12px;
    background: ${color._f1fbff};
    padding: 1px 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 17px;
  }
  .x1 {
    color: ${color._16384e};
    margin-top: 3px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .price {
    color: ${color._16384e};
    margin-top: 3px;
    display: flex;
    justify-content: end;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;
export const Note = styled.div`
  background: ${color._f6fafb};
  height: 101px;
  display: flex;
  gap: 8px;
  padding-top: 12px;
  .circle {
    width: 14px;
    height: 14px;
    margin-left: 12px;
    position: relative;
    top: -4px;
  }

  .above {
    color: ${color._16384e};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.9%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
`;
export const FirstConatiner = styled.div`
  flex: 0.6;
`;
export const SecondContainer = styled.div`
  flex: 0.4;
`;
export const MidContainer = styled.div`
  width: 419px;
  background: ${color._f6fafb};
  padding: 28px;
  .subtotal {
    width: 326px;
  }
`;
export const OrderDetail = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .x1 {
    color: ${color._16384e};

    display: flex;
    justify-content: end;
    margin-top: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .price {
    width: 111px;
    height: 17px;
    background: ${color._f1fbff};
    color: ${color._5193aa};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .gsat {
    margin-top: 6px;
    color: #16384e;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .course {
    width: 84px;
    height: 17px;
    background: ${color._f1fbff};
    color: ${color._5193aa};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .qty {
    width: 30px;
    height: 17px;
    background: ${color._f1fbff};
    color: ${color._5193aa};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const OrderBox = styled.div`
  width: 82%;
`;
export const OrderNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const Payment = styled.div`
  padding-bottom: 32px;
  color: ${color._16384e};
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
export const ParagraphGoogle = styled.div`
  padding-top: 44px;
  padding-bottom: 28px;
`;
export const Purchase = styled.div`
  padding-bottom: 51px;
  color: ${color._16384e};
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
export const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;
  .number {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 800;
  }
`;
export const Total = styled.div`
  padding-bottom: 64px;
  display: flex;
  justify-content: space-between;
  .number {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 800;
  }
`;
export const AccordianItem = styled(Accordion)`
  .accordion-button {
  }
`;
export const Confirm = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 44px;
`;
export const Container = styled.div`
  padding-bottom: 20%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .banner {
    display: flex;
    flex-direction: column;
  }
  .title {
    color: var(--Text-Color, ${color._08232f});
    padding-top: 51px;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .line {
    background: ${color._ffc636};
    width: 167px;
    height: 5px;
    margin-bottom: 20px;
  }
  .order {
    color: var(--Text-Color, ${color._08232f});
    padding-bottom: 35px;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .billing {
    color: var(--Text-Color, ${color._08232f});
    padding-bottom: 31px;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const FormInput = styled.div`
  border-radius: 4px;
  background: ${color._fff};
  width: 633px;
  height: 42px;
  .input {
    width: 98%;
    border: none;
  }
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${color._EFF3F9};
  margin: 10px auto;
`;
export const FormData = styled.div`
  display: flex;
  flex-direction: column;
  .order {
    color: ${color._5193aa};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .label {
    color: ${color._071B28};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .input {
    width: 303px;
    outline: none;
    border: none;
    background: ${color._FBFBFB};
    border-radius: 4px;
    height: 42px;
    padding: 10px;
  }
  .label::after {
    content: "*";
    color: ${color._F00E00};
    font-size: 25px;
    font-weight: 400;
  }
  .billing {
    color: ${color._5193aa};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 15px;
  }
`;
export const InputField = styled.div`
  .orderNotes {
    display: flex;
    flex-direction: column;
  }
  .optional {
    color: ${color._5193aa};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .info {
    margin-top: 18px;
    color: ${color._071b28};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .option {
    border-radius: 4px;
    outline: none;
    border: ${color._fbfbfb};
    background: ${color._fbfbfb};
    width: 633px;
    height: 89px;
  }
  .label {
    color: ${color._071b28};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .input {
    width: 621px;
    height: 42px;
  }
`;
export const Additinal = styled.div`
  padding-bottom: 31px;
  color: ${color._071b28};
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
export const OrderNotes = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color._071b28};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .inputField {
    width: 633px;
    height: 89px;
    flex-shrink: 0;
    border: none;
    background: ${color._fbfbfb};
    width: 100%;
  }
`;
export const FormDataContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
export const BuyCourseButton = styled.div`
  .buy_course {
    border-radius: 2px;
    background: ${color._3ba32a};
    color: ${color._fff};
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    padding: 10px 0px;
    width: 100%;
  }
`;
export const HeaderPaypal = styled.div`
  border: none;
`;
export const BankDetail = styled.div`
  .accordion-button {
    width: 365px;
    height: 37px;
  }
`;
export const PaypalText = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  color: ${color._16384E};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  .cardicon {
    font-size: 24px;
    color: ${color._16384E};
    font-weight: 600;
  }
`;
export const LabelText = styled.div`
  .inputText {
    color: ${color._6090a8};
    border-radius: 4px;
    background: ${color._fbfbfb};
    border: none;
    height: 42px;
    outline: none;
    padding-left: 10px;
  }
  .label::after {
    content: "*";
    color: ${color._F00E00};
    font-size: 18px;
    font-weight: 500;
  }
  color: ${color._15374d};
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .boxsize {
    width: 48%;
  }
`;
export const SubmitMessage = styled.div`
  height: 231px;
  position: relative;
  top: 18%;
  width: 75%;
  margin: 0 auto;
  background-color: ${color._F1FBFFE3};
  position: relative;
  top: 9%;
  color: ${color._16384e};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Message = styled.div`
  .tick {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
  .success {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    color: ${color._16384e};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .receive {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .after {
    width: 474px;
    text-align: center;
    color: ${color._16384e};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const Result = styled.div`
  .payment {
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
    background: ${color._15374D};
    color: ${color._ffffff};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  border-radius: 0px 0px 20px 20px;
  background: rgba(241, 251, 255, 0.89);
  width: 843px;
  height: 309px;
`;
export const ResultPayment = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
export const IconMap = styled.div`
  display: flex;
  gap: 22px;
  justify-content: center;
  margin: 20px auto;
`;
export const ContentContainer = styled.div`
  margin-top: 30px;
`;
export const ResultConfirm = styled.div`
  .payment {
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
    background: ${color._15374D};
    color: ${color._ffffff};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  border-radius: 0px 0px 20px 20px;
  background: rgba(241, 251, 255, 0.89);
  width: 703px;
  height: 309px;
`;
